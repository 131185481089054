import chevronIconSVG from "../../icons/chevron-down.svg?raw";
import {
  animate,
  assignAttributes,
  closest,
  createElement,
  getAttributes,
  replaceChildren,
  uniqueId,
} from "../utils";

/**
 * A disclosure component.
 *
 * The component is essentially a progressively enhanced `<details>` element.
 *
 * It expects to be initialized with a single `<details>` child element. During
 * initialization, this `<details>` element and any descendant `<summary>`
 * element will be "upgraded" as the component's content and
 * trigger respectively.
 *
 * Like the native `<details>`, the component's state is indicated with the
 * boolean attribute `open`. The attribute is added and removed by the
 * component in response to user interaction.
 *
 * The component can be controlled with JavaScript by adding and removing the
 * `open` attribute on the element or by setting the `open` property on the
 * component DOM instance (e.g. `disclosureElement.open = false`).
 *
 * Also like the native `<details>`, the component emits a `toggle` event when
 * its state changes. The event is emitted immediately after the `open`
 * attribute is changed, but before any animations are begun and changes made
 * to the component's visibility.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details | <details> (MDN)}
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/ | WAI ARIA APG Disclosure Pattern}
 *
 * @example ```html
 * <!-- Default usage -->
 * <sasb-disclosure>
 *   <details>
 *     <summary>Disclosure button text</summary>
 *     Disclosure content. Lorem ipsum...
 *   </details>
 * </sasb-disclosure>
 *
 * <!-- Initialliy expanded -->
 * <sasb-disclosure>
 *   <details open>
 *     <summary>Disclosure button text</summary>
 *     <h3>This content will be visible by default, even before any user interaction.</h3>
 *     <p>Lorem ipsum...</p>
 *   </details>
 * </sasb-disclosure>
 * ```
 */
export default class Disclosure extends HTMLElement {
  /**
   * Define the element in the document's custom element registry.
   *
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-disclosure") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  constructor() {
    super();
    this.cleanup = new Set();
  }

  /**
   * A collection for side effect cleanup functions. Each function in the set
   * is called and removed when the component is disconnected.
   *
   * @type {Set<() => void>}
   */
  cleanup;

  /**
   * The current state of the component.
   *
   * @type {boolean}
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the component.
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   *
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  async attributeChangedCallback(name, prev, next) {
    // Toggle component state when the `open` attribute changes.
    if (name === "open") {
      const button = this.querySelector(".disclosure-item-button");
      const content = this.querySelector(".disclosure-item-content");
      const container = this.querySelector(".disclosure-item-container");

      // Abort if we don't have the expected markup.
      if (
        !(button instanceof HTMLElement) ||
        !(container instanceof HTMLElement) ||
        !(content instanceof HTMLElement)
      ) {
        return;
      }

      // Dispatch a "toggle" event before making any changes.
      this.dispatchEvent(
        new Event("toggle", { bubbles: true, cancelable: true }),
      );

      // Get the new state of the component.
      const isOpen = next !== null;

      // Set the `aria-expanded` attribute.
      button.setAttribute("aria-expanded", isOpen.toString());

      // Toggle component state.
      container.style.overflow = "hidden";
      content.style.visibility = null;
      await animate(container, [{ gridTemplateRows: isOpen ? "1fr" : "0fr" }]);
      container.style.overflow = isOpen ? null : "hidden";
      content.style.visibility = isOpen ? null : "hidden";
    }
  }

  /**
   * Set up the component once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected.
    if (!this.isConnected) {
      return;
    }

    // Set up component markup
    this.setup();

    // Rerun setup when component children change.
    const observer = new MutationObserver(() => this.setup());
    observer.observe(this, { childList: true });
    this.cleanup.add(() => observer.disconnect());

    /**
     * Toggle the component state on button click events.
     *
     * @param {PointerEvent} event A PointerEvent object.
     */
    const handleClick = (event) => {
      if (closest(event.target, ".disclosure-item-button")) {
        this.open = !this.open;
      }
    };

    // Add the click event handler.
    this.addEventListener("click", handleClick);
    this.cleanup.add(() => this.removeEventListener("click", handleClick));
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Set up the component markup.
   *
   * Note that this method may be called repeatedly and should be idempotent.
   */
  setup() {
    // Abort if the component markup has already been set up.
    if (
      this.children.length > 0 &&
      this.firstElementChild.matches(".disclosure-item-button")
    ) {
      return;
    }

    // Get the first descendant `<details>` element.
    const details = this.querySelector("details");

    // Abort if no child details element exists.
    if (!details) {
      return;
    }

    // Copy attributes from the details element.
    assignAttributes(this, getAttributes(details));

    // Get the descendant `<summary>` element.
    let summary = details.querySelector("summary");

    if (summary) {
      // Remove the summary element if it exists. This lets us easily reference
      // all other child nodes of the details element.
      summary.remove();
    } else {
      // Create a default summary element if none exists.
      summary = createElement("summary", { textContent: "Details" });
    }

    // Create a unique id for aria purposes.
    const id = uniqueId(this.tagName);

    // Get the current component state.
    const isOpen = this.hasAttribute("open") || details.open;

    this.toggleAttribute("open", isOpen);

    /**
     * The component trigger button.
     */
    const button = createElement(
      "button",
      {
        ...getAttributes(summary),
        "aria-controls": id,
        "aria-expanded": isOpen ? "true" : "false",
        id: `${id}-button`,
        classList: "disclosure-item-button",
        type: "button",
      },
      [
        // The item trigger icon
        createElement("span", {
          "aria-hidden": "true",
          classList: "sasb-icon disclosure-item-icon",
          innerHTML: chevronIconSVG,
        }),
        ...summary.childNodes,
      ],
    );

    /**
     * The component content.
     */
    const content = createElement(
      "div",
      {
        "aria-labelledby": `${id}-button`,
        classList: "disclosure-item-content",
        id,
        role: "region",
        style: {
          minHeight: 0,
          visibility: isOpen ? null : "hidden",
        },
      },
      details.childNodes,
    );

    /**
     * A content container included for styling concerns.
     */
    const container = createElement(
      "div",
      {
        classList: "disclosure-item-container",
        tabIndex: -1,
        style: {
          display: "grid",
          gridTemplateRows: isOpen ? "1fr" : "0fr",
          overflow: isOpen ? null : "hidden",
        },
      },
      content,
    );

    // Replace the component content with our new markup.
    replaceChildren(this, button, container);
  }
}

Object.assign(Disclosure, { observedAttributes: ["open"] });

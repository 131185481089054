/**
 * Escapes special characters in the provided string, making it suitable for
 * use in RegExp().
 *
 * Adapted from MDN's regular expressions guide.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 *
 * @param {string} str The string to escape.
 * @returns {string} The escaped string.
 */
export function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * Checks if the provided value is a plain object.
 *
 * @param {*} obj The value to check.
 * @returns {boolean} Whether or not the value is a plain object.
 */
export function isPlainObject(obj) {
  return !!obj && Object.prototype.toString.call(obj) === "[object Object]";
}

/**
 * Checks if the user has indicated a preference for reduced motion.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 *
 * @returns {boolean}
 */
export const prefersReducedMotion = (() => {
  const media =
    "matchMedia" in window
      ? window.matchMedia("prefers-reduced-motion")
      : { matches: false };
  return () => media.matches;
})();

/**
 * Generates a unique id optionally prefixed with the provided string
 *
 * @param {string|undefined} prefix Optional. The id prefix.
 * @returns {string} A unique id.
 */
export const uniqueId = (() => {
  let index = -1;
  return (prefix = "id") => {
    const id =
      typeof crypto.randomUUID === "function" ? crypto.randomUUID() : ++index;
    return `${prefix}-${id}`;
  };
})();

/**
 *
 * @param {*} target
 */
export function lockDocumentScroll(target) {
  if (!document.body.dataset.scrollLocked) {
    document.body.querySelectorAll(":scope > *").forEach((el) => {
      if (
        el instanceof HTMLElement &&
        !el.inert &&
        !el.matches("link,meta,script,style") &&
        !el.contains(target) &&
        el.offsetParent !== null &&
        el.getAttribute("aria-hidden") !== "true"
      ) {
        el.inert = true;
        el.dataset.scrollLocked = "true";
      }
    });
    document.body.dataset.scrollLocked = "true";
    document.documentElement.style.overflow = "hidden";
  }
}

/**
 *
 */
export function unlockDocumentScroll() {
  document.body.querySelectorAll("[data-scroll-locked]").forEach((el) => {
    if (el instanceof HTMLElement) {
      el.inert = false;
      delete el.dataset.scrollLocked;
    }
  });
  delete document.body.dataset.scrollLocked;
  document.documentElement.style.removeProperty("overflow");
}

/**
 *
 * @param {string} key
 * @returns {string|undefined}
 */
export function getCookie(key) {
  if (key) {
    try {
      const entries = document.cookie
        .split(";")
        .map((str) => str.split(/^\s*([^=]*)=(.+)\s*$/).filter(Boolean));
      const params = new URLSearchParams(entries);
      return params.get(key);
    } catch (error) {
      // Ignore
    }
  }
  return undefined;
}

const STORAGE_KEY_PREFIX = "SASB_";

const storage = Object.assign(createStorage(), {
  session: createStorage(sessionStorage),
});

export default storage;

function createStorage(storageObject = localStorage) {
  const getKey = (str) => `${STORAGE_KEY_PREFIX}${str}`;
  const serialize = (obj) => JSON.stringify(obj);
  const unserialize = (str) => JSON.parse(str);

  return {
    /**
     * @param {string} key
     * @returns {*}
     */
    get(key) {
      try {
        return unserialize(storageObject.getItem(getKey(key)));
      } catch (error) {
        return undefined;
      }
    },
    /**
     * @param {string} key
     */
    remove(key) {
      try {
        storageObject.removeItem(getKey(key) || undefined);
      } catch (error) {
        // Ignore
      }
    },
    /**
     * @param {string} key
     * @param {*} value
     */
    set(key, value) {
      try {
        storageObject.setItem(getKey(key), serialize(value));
      } catch (error) {
        // Ignore
      }
    },
    /**
     *
     * @param {string} key
     * @param {<T,>(data: T) => T} callback
     */
    update(key, callback) {
      try {
        this.set(key, callback(this.get(key)));
      } catch (error) {
        // Ignore
      }
    },
  };
}

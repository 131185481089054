import chevronIcon from "../../icons/chevron-down.svg?raw";
import {
  animate,
  assignAttributes,
  closest,
  createElement,
  focus,
  getAttributes,
  uniqueId,
} from "../utils";

/**
 * A component for handling nav menus with "flyout" disclosure items.
 *
 * The component should include the attributes and behaviors described in the
 * {@link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation-hybrid/ | Disclosure Navigation Menu with Top-Level Links Example}.
 *
 * The component expects child disclosure items to use `<details>` markup.
 *
 * @example
 * ```
 * <sasb-navigation-menu>
 *   <ul>
 *     <li>
 *       <a href="...">A top-level menu item</a>
 *     </li>
 *     <li>
 *       <details>
 *         <summary>A "flyout" disclosure item</summary>
 *         <ul>
 *           <li>
 *             <a href="...">A nested menu item</a>
 *           </li>
 *         </ul>
 *       </details>
 *     </li>
 *   </ul>
 * </sasb-navigation-menu>
 * ```
 */
export default class NavigationMenu extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-navigation-menu") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();

    // Import floating-ui
    if (!window.FloatingUIDOM) {
      window.FloatingUIDOM = import("@floating-ui/dom");
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Add `data-defined="true"`.
    // This is primarily used as a selector for styling, which we can remove
    // someday when the `:defined` pseudo-selector has better browser support.
    if (!this.dataset.defined) {
      this.querySelectorAll("details").forEach((el) => {
        const summary = el.querySelector("summary");

        if (summary && el.parentElement) {
          // Remove the summary element so we can more easily select the
          // remaining children elements
          summary.remove();

          const id = uniqueId("sasb-navigation-menu-");
          const link = summary.querySelector("a");

          if (link) {
            link.remove();
          }

          el.parentElement.classList.add("disclosure-item");

          // Create new component markup
          el.replaceWith(
            link || "",
            createElement(
              "button",
              {
                ...getAttributes(summary),
                "aria-controls": id,
                "aria-expanded": "false",
                classList: "disclosure-item-button",
                type: "button",
              },
              [
                ...summary.childNodes,
                createElement("span", {
                  "aria-hidden": "true",
                  classList: "sasb-icon disclosure-item-button-icon",
                  innerHTML: chevronIcon,
                }),
              ],
            ),
            createElement(
              "div",
              { classList: "disclosure-item-content", id },
              el.children,
            ),
          );
        }
      });

      this.dataset.defined = "true";
    }

    // Create an observer to react to changes of the "aria-expanded" attribute
    // on disclosure trigger buttons.
    const observer = new MutationObserver((records) => {
      records.forEach(async (record) => {
        // Only react to changes of the aria-expanded attribute
        if (record.attributeName !== "aria-expanded") {
          return;
        }

        const button = record.target instanceof HTMLElement && record.target;
        const id = button && button.getAttribute("aria-controls");
        const popover = id && document.getElementById(id);
        const arrow =
          popover && popover.querySelector(".disclosure-item-content-arrow");

        // Abort early if we don't have the expected markup
        if (!button || !popover) {
          return;
        }

        /** @type {typeof import("@floating-ui/dom")} */
        let floatingUI;

        try {
          floatingUI = await window.FloatingUIDOM;
        } catch (error) {
          return;
        }

        if (!floatingUI) {
          return;
        }

        const {
          arrow: arrowMiddleware,
          autoUpdate,
          computePosition,
          shift,
        } = floatingUI;

        // Compute and set popover position with Floating UI
        const setPosition = async () => {
          // Calculate the pixel value of a single rem
          const rem = parseInt(
            getComputedStyle(document.documentElement).fontSize,
          );
          // Get the width in pixels of the popover element. Should be equal
          // to that set by the `.container` utility class.
          // e.g. `width: 75rem; max-width: min(100%, 100vw - 3rem);`
          const containerWidth = Math.min(
            75 * rem,
            window.innerWidth - 2.5 * rem,
          );
          const containerGutter = (window.innerWidth - containerWidth) / 2;
          // Compute position
          const position = await computePosition(button, popover, {
            strategy: "fixed",
            placement: "bottom",
            middleware: [
              shift(() => ({
                padding: { left: containerGutter, right: containerGutter },
              })),
              !!arrow && arrowMiddleware({ element: arrow }),
            ],
          });
          // Assign styles
          Object.assign(popover.style, {
            left: `${position.x}px`,
            top: `${position.y}px`,
            position: position.strategy,
          });
          // Handle arrow placement
          if (arrow && position.middlewareData.arrow) {
            const { x } = position.middlewareData.arrow;
            Object.assign(arrow.style, {
              left: x !== null ? `${x}px` : "",
              top: position.placement.includes("top") ? "" : 0,
              bottom: position.placement.includes("bottom") ? "" : 0,
            });
          }
        };

        // Get all other currently expanded items
        const expandedSiblings = this.querySelectorAll(
          `[aria-expanded='true']:not([aria-controls='${id}'])`,
        );

        // Get the new button state
        const isExpanded = button.getAttribute("aria-expanded") === "true";

        if (isExpanded) {
          // Close any other expanded items
          expandedSiblings.forEach((el) => {
            el.setAttribute("aria-expanded", "false");
          });

          // Un-hide the popover element
          popover.hidden = false;

          // Save Floating UI clean up functions
          popover.__floatingUiCleanup = autoUpdate(
            button,
            popover,
            setPosition,
          );
          this.cleanup.add(() => {
            if (popover.__floatingUiCleanup) {
              popover.__floatingUiCleanup();
              delete popover.__floatingUiCleanup;
            }
          });
        }

        // Animate the popover in/out
        await animate(
          popover,
          [
            {
              opacity: isExpanded ? 1 : 0,
              transform: `translateY(${isExpanded ? "0" : "1rem"})`,
            },
          ],
          // Only use "long" animations if no other items are expanded
          { duration: expandedSiblings.length > 0 ? 10 : 150 },
        );

        // On collapse, hide the popover once the animation has completed.
        if (button.getAttribute("aria-expanded") !== "true") {
          popover.hidden = true;
          if (popover.__floatingUiCleanup) {
            popover.__floatingUiCleanup();
            delete popover.__floatingUiCleanup;
          }
        }
      });
    });

    // Destroy the observer on disconnect
    this.cleanup.add(() => observer.disconnect());

    // Handle clicks outside the menu
    document.addEventListener("click", this);
    this.cleanup.add(() => document.removeEventListener("click", this));

    // Handle "Escape" key presses
    document.addEventListener("keydown", this);
    this.cleanup.add(() => document.removeEventListener("keydown", this));

    // Set up disclosure menu items
    this.querySelectorAll("[aria-expanded][aria-controls]").forEach(
      (button) => {
        const id = button.getAttribute("aria-controls");
        const popover = id && document.getElementById(id);
        const menuItem = button.parentElement;

        // Abort early if we don't have the expected markup
        if (!popover || !menuItem) {
          return;
        }

        // Prepend an "arrow" element to the popover if none exists
        if (!popover.querySelector(".disclosure-item-content-arrow")) {
          popover.prepend(
            createElement("span", {
              "aria-hidden": "true",
              classList: "disclosure-item-content-arrow",
            }),
          );
        }

        // Set the initial, "collapsed" state
        button.setAttribute("aria-expanded", "false");
        assignAttributes(popover, {
          hidden: true,
          style: { opacity: 0, transform: "translateY(1rem)" },
        });

        // Observe the button's aria-expanded attribute
        observer.observe(button, {
          attributes: true,
          attributeFilter: ["aria-expanded"],
        });

        // Bind event listeners
        button.addEventListener("click", this);
        menuItem.addEventListener("focusout", this);
        menuItem.addEventListener("mouseenter", this);
        menuItem.addEventListener("mouseleave", this);

        // Remove event listeners on disconnect
        this.cleanup.add(() => {
          button.removeEventListener("click", this);
          menuItem.removeEventListener("focusout", this);
          menuItem.removeEventListener("mouseenter", this);
          menuItem.removeEventListener("mouseleave", this);
        });
      },
    );
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Handle events
   * @param {Event} event
   * @returns {void}
   */
  handleEvent(event) {
    /**
     * @param {*} el The disclosure trigger button
     * @param {Boolean} [state] The new state
     */
    const setDisclosureState = (el, state) => {
      if (el instanceof HTMLElement && el.hasAttribute("aria-expanded")) {
        if (this.disclosureCollapseTimeout) {
          clearTimeout(this.disclosureCollapseTimeout);
          this.disclosureCollapseTimeout = null;
        }

        const currentState = el.getAttribute("aria-expanded") === "true";
        const nextState = typeof state === "boolean" ? state : !currentState;
        if (nextState !== currentState) {
          el.setAttribute("aria-expanded", nextState.toString());
        }
      }
    };

    // Toggle the disclosure when the button is clicked
    if (
      event.type === "click" &&
      event.currentTarget instanceof HTMLElement &&
      this.contains(event.currentTarget) &&
      event.currentTarget.matches("[aria-expanded][aria-controls]")
    ) {
      setDisclosureState(event.currentTarget);
      return;
    }

    // Collapse all items if a click occurs outside the component
    if (
      event.type === "click" &&
      !(event.target instanceof Node && this.contains(event.target))
    ) {
      this.querySelectorAll("[aria-expanded][aria-controls]").forEach((el) => {
        setDisclosureState(el, false);
      });
      return;
    }

    // Collapse the disclosure when the escape key is pressed
    if (event.type === "keydown" && event.key === "Escape") {
      if (event.target instanceof Node && this.contains(event.target)) {
        const menuItem = closest(event.target, ".menu-item-has-popover");
        const button =
          menuItem && menuItem.querySelector("[aria-expanded][aria-controls]");
        if (menuItem && button) {
          event.preventDefault();
          focus(button);
        }
      }
      this.querySelectorAll("[aria-expanded][aria-controls]").forEach((el) => {
        setDisclosureState(el, false);
      });
      return;
    }

    const menuItem =
      event.currentTarget instanceof HTMLElement &&
      event.currentTarget.matches(".disclosure-item") &&
      event.currentTarget;

    const button =
      menuItem && menuItem.querySelector("[aria-expanded][aria-controls]");

    if (menuItem && button) {
      // Collapse the disclosure on focusout
      if (
        event.type === "focusout" &&
        !(event.relatedTarget && menuItem.contains(event.relatedTarget))
      ) {
        setDisclosureState(button, false);
      }

      // Expand the disclosure on menu item mousenter
      if (event.type === "mouseenter" && menuItem.matches(".menu-item")) {
        setDisclosureState(button, true);
      }

      // Collapse the disclosure on menu item mouseleave
      if (
        event.type === "mouseleave" &&
        menuItem.matches(".menu-item") &&
        !(document.activeElement && menuItem.contains(document.activeElement))
      ) {
        this.disclosureCollapseTimeout = setTimeout(() => {
          setDisclosureState(button, false);
        }, 400);
      }
    }
  }
}

import { createElement, replaceChildren } from "../utils";

/**
 * A "Read more/less" component.
 *
 * The component hides textContent longer than the provided length threshold,
 * inserting a button to toggle its visibility. The threshold and button text
 * may be specified via attributes.
 *
 * Note that only child text nodes are supported: the component will strip out
 * inner HTML content.
 *
 * Example:
 *
 * ```
 * <sasb-truncated-text
 *   length="100"
 *   expand-label="Read more"
 *   collapse-label="Read less"
 * >
 *   Lorem ipsum dolor sit amet...
 * </sasb-truncated-text>
 * ```
 */
export default class TruncatedText extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-truncated-text") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  static observedAttributes = ["open"];

  get open() {
    return this.hasAttribute("open");
  }

  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Options getter.
   *
   * Returns the component options specified via attributes. Defaults are used for missing
   * values.
   *
   * @returns {object} The component options.
   */
  get options() {
    // Default component options.
    const defaults = {
      labels: {
        expand: "More",
        collapse: "Less",
      },
      length: 150,
    };

    const labels = {
      expand: this.getAttribute("expand-label") || defaults.labels.expand,
      collapse: this.getAttribute("collapse-label") || defaults.labels.collapse,
    };
    let length = Number(this.getAttribute("length"));
    length = length && !Number.isNaN(length) ? length : defaults.length;
    return { labels, length };
  }

  /**
   * Creates a new TruncatedText instance.
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();
  }

  attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const button = this.querySelector("[role='button']");
      if (button) {
        const isExpanded = next !== null;
        const { labels } = this.options;
        button.textContent = isExpanded ? labels.collapse : labels.expand;
      }
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup if necessary
    if (!this.dataset.defined) {
      const textContent = (this.textContent || "").trim();

      // Only initialize the component if the textContent length is greater
      // than provided threshold.
      if (!textContent) {
        return;
      }

      const { length } = this.options;

      let index = length;

      // Try to find a "pretty" place to split the text (e.g. at word boundaries and
      // non-alphanumeric characters like hyphens)
      const re = /(\b\s)/;
      if (re.test(textContent)) {
        while (index > 0 && !re.test(textContent.slice(index - 1, index + 1))) {
          index -= 1;
        }
      }

      // If the "pretty" truncated text would be much shorter than the desired length, then just
      // split using the provided length option.
      if (textContent.slice(0, index).length < length - length ** 0.6) {
        index = length;
      }

      replaceChildren(
        this,
        // Create an element for the truncated text
        createElement("span", {
          textContent: textContent.slice(0, index),
        }),
        // Create an element for the overflow text
        createElement("span", {
          textContent: textContent.slice(index),
        }),
        // Add an en-space character to separate the visible text and toggle
        document.createTextNode("\u2002"),
        // Create a toggle button
        createElement("span", {
          role: "button",
          tabIndex: 0,
          textContent: this.options.labels.expand,
        }),
      );

      this.dataset.defined = "true";
    }

    const button = this.querySelector("[role='button']");

    if (button) {
      // Add event handlers
      const handleClick = () => {
        this.open = !this.open;
      };

      const handleKeydown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
          event.preventDefault();
          this.open = !this.open;
        }
      };

      button.addEventListener("click", handleClick);
      button.addEventListener("keydown", handleKeydown);

      this.cleanup.add(() => {
        button.removeEventListener("click", handleClick);
        button.removeEventListener("keydown", handleKeydown);
      });
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }
}

/* global Bloodhound */
/* eslint-disable no-inner-declarations */

import {
  closest,
  createElement,
  escapeRegExp,
  isFormControl,
  isPlainObject,
  uniqueId,
} from "../utils";

/**
 * The typeahead datasets used for autocomplete of company/organization inputs.
 * @type {Record<string, null|Promise<Record<string, unknown>>>}
 */
const autoCompleteDatasets = {
  hsFormCompanies: null,
  sicsAutocomplete: null,
};

/**
 * A form component.
 *
 * The component adds basic functionality such as showing/hiding validation
 * error messages and autocomplete for specific fields.
 */
export default class Form extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-form") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Adds autocomplete behavior to the provided text input using company data
   * retrieved from the SASB data API.
   * @param {HTMLInputElement} el The text input element to use
   */
  static async attachCompanyAutocomplete(el) {
    if (el instanceof HTMLInputElement && !el.dataset.setup) {
      // Disable native autocomplete for the element.
      el.autocomplete = "off";

      // Mark the element as set up to prevent repeated initialization.
      el.dataset.setup = "true";

      try {
        // Create typeahead dataset
        const dataset = await getDataset();

        // Instantiate typeahead
        jQuery(el).typeahead(
          { highlight: true, hint: true, minLength: 2 },
          dataset,
        );
      } catch (error) {
        console.error(error);
      }

      async function getDataset() {
        const form = el.form;
        let endpoint = form.matches(".hs-form")
          ? "hsFormCompanies"
          : "sicsAutocomplete";
        if (
          endpoint in autoCompleteDatasets &&
          !autoCompleteDatasets[endpoint]
        ) {
          const url = new URL(window.SASB.restEndpoints[endpoint]);
          autoCompleteDatasets[endpoint] = fetch(url)
            .then((res) => res.json())
            .then((companies) => {
              let source = (query, cb) => {
                const re = new RegExp(escapeRegExp(query), "i");
                cb(companies.filter((str) => re.test(str)));
              };

              // Bloodhound is a suggestion engine that may come bundled with
              // typeahead.js. See {@link https://github.com/corejavascript/typeahead.js/blob/master/doc/bloodhound.md}
              if (typeof Bloodhound !== "undefined") {
                source = new Bloodhound({
                  local: companies,
                  queryTokenizer: Bloodhound.tokenizers.whitespace,
                  datumTokenizer: Bloodhound.tokenizers.whitespace,
                });
              }

              return { name: "hsFormCompanies", source };
            });
        }
        return await autoCompleteDatasets[endpoint];
      }
    }
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();

    /**
     * @type {Record<string, unknown>}
     */
    this._ = {};
  }

  /**
   * @type {HTMLFormElement|null}
   */
  get form() {
    if (!(this._.form instanceof HTMLFormElement)) {
      this._.form = this.querySelector("form");
    }
    return /** @type {HTMLFormElement|null} */ (this._.form);
  }

  /**
   * @type {Record<string, *>}
   */
  get validation() {
    if (!isPlainObject(this._.validation) && this.dataset.validation) {
      try {
        const value = JSON.parse(this.dataset.validation);
        this._.validation = value;
      } catch (error) {
        //
      }
    }
    return /** @type {Record<string, *>} */ (this._.validation || {});
  }

  /**
   *
   * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} el The element to check.
   * @returns {boolean} The current validity of the provided element.
   */
  checkCustomValidity(el) {
    if (!isFormControl(el) || !this.form) {
      return false;
    }

    const validation = this.validation[el.name];
    if (!isPlainObject(validation)) {
      return true;
    }

    const proxy = this.form.elements.namedItem(`${el.name}-validity`);
    const formData = new FormData(this.form);

    if (!(proxy instanceof HTMLInputElement) || !formData) {
      return false;
    }

    const rules = Object.entries(validation);

    for (const [key, value] of rules) {
      switch (key) {
        case "max": {
          if (formData.getAll(el.name).length > Number(value)) {
            proxy.value = "";
            proxy.setCustomValidity(
              `Please choose no more than ${value} items.`,
            );
            proxy.reportValidity();
            return false;
          } else {
            proxy.setCustomValidity("");
            proxy.value = "true";
            proxy.dispatchEvent(
              new Event("change", { bubbles: true, cancelable: true }),
            );
            proxy.reportValidity();
          }
          break;
        }
      }
    }

    return true;
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    const form = this.form;

    // Abort if no child `<form>` element exists.
    if (!form) {
      return;
    }

    // Set up component markup
    this.setup();

    /**
     * A form control validation handler. The function shows or hides error
     * messages based on the validity of the related input (derived from
     * `Element.validity.valid`)
     *
     * @param {Event} event A validation event
     */
    const handleValidation = (event) => {
      const el = event.target instanceof HTMLElement && event.target;
      const field = closest(el, ".form-field");

      if (!isFormControl(el)) {
        return;
      }

      const isValid = el.validity.valid;

      if (!el.id) {
        el.id = uniqueId(`${this.tagName}-${el.tagName}`);
      }

      const errormessage =
        document.getElementById(
          el.getAttribute("aria-errormessage") || `${el.id}-errormessage`,
        ) ||
        createElement("small", {
          classList: "form-field-error-message",
          id: `${el.id}-errormessage`,
        });

      if (!isValid) {
        el.setAttribute("aria-invalid", "true");
        el.setAttribute("aria-errormessage", errormessage.id);
        errormessage.textContent = el.validationMessage;
        if (field) {
          field.classList.add("invalid");
          field.append(errormessage);
        } else {
          el.after(errormessage);
        }
      }

      if (isValid) {
        el.removeAttribute("aria-invalid");
        el.removeAttribute("aria-errormessage");
        errormessage.remove();
        if (field) {
          field.classList.remove("invalid");
        }
      }

      this.checkCustomValidity(el);
    };

    /**
     * A form submit event handler. If the form includes a visible hCapctha
     * element, then we ensure the user has provided a response before
     * submitting.
     * @param {SubmitEvent} event
     */
    const handleSubmit = (event) => {
      const hCaptchaResponse = form.elements.namedItem("h-captcha-response");
      if (
        isFormControl(hCaptchaResponse) &&
        !closest(hCaptchaResponse, ".h-captcha[data-size='invisible']")
      ) {
        hCaptchaResponse.required = true;
        if (!hCaptchaResponse.checkValidity()) {
          event.preventDefault();
        }
      }
    };

    // Bind validation event handlers
    form.addEventListener("change", handleValidation);
    form.addEventListener("invalid", handleValidation, true);
    form.addEventListener("submit", handleSubmit);

    // Remove validation event handlers when the component is disconnected
    this.cleanup.add(() => {
      form.removeEventListener("change", handleValidation);
      form.removeEventListener("invalid", handleValidation, true);
      form.removeEventListener("submit", handleSubmit);
    });
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Set up the component markup.
   *
   * Note that this method may be called repeatedly and should be idempotent.
   */
  setup() {
    // Remove `?sasb-form-error` search params if any exist.
    try {
      const url = new URL(window.location.toString());
      if (url.searchParams.has("sasb-form-error")) {
        url.searchParams.delete("sasb-form-error");
        window.history.replaceState({}, "", url);
      }
    } catch (error) {
      // ignore
    }

    this.querySelectorAll("input").forEach((el) => {
      // Set up company autocomplete comboboxes.
      if (el.matches("[name='company'],[autocomplete='organization']")) {
        Form.attachCompanyAutocomplete(el);
      }
      // Ensure "proxy" validation controls are required.
      // This enables custom validation for e.g. checkbox groups.
      if (el.name.endsWith("-validity")) {
        el.required = true;
      }
    });
  }
}

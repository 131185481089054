import storage from "../modules/storage";
import { createElement } from "../utils";

import Disclosure from "./Disclosure";

/**
 * An accordion component.
 *
 * This component should include the attributes and behaviors described in the
 * {@link https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/|WAI ARIA APG Accordion Example}.
 *
 * Accordion items to be specified as child `<details>` elements.
 *
 * @example ```html
 * <sasb-accordion>
 *   <details>
 *     <summary>Accordion item 1</summary>
 *     <p>Lorem ipsum...</p>
 *     <p>Lorem ipsum...</p>
 *   </details>
 *   <details>
 *     <summary>Accordion item 2</summary>
 *     <p>Lorem ipsum...</p>
 *   </details>
 * </sasb-accordion>
 * ```
 */
export default class Accordion extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-accordion") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected.
    if (!this.isConnected) {
      return;
    }

    this.setup();

    /**
     * Listen for toggling of accordion items.
     */
    const handleToggle = () => this.setPersistentState();

    this.addEventListener("toggle", handleToggle);
    this.cleanup.add(() => this.removeEventListener("toggle", handleToggle));
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  setup() {
    this.restorePersistentState();

    const items = Array.from(
      this.querySelectorAll("details, sasb-disclosure"),
    ).map((el) => {
      if (el instanceof HTMLDetailsElement) {
        return createElement(
          "sasb-disclosure",
          { classList: "accordion-item" },
          [el],
        );
      } else if (el instanceof Disclosure) {
        el.classList.add("accordion-item");
        return el;
      }
    });

    this.replaceChildren(...items);
  }

  restorePersistentState() {
    if (this.id) {
      const data = storage.session.get(this.tagName);
      const key = window.location.pathname;
      const items = data && data[key] && data[key][this.id];
      console.log({ data, key, items });
      if (Array.isArray(items) && !this.dataset.defined) {
        Array.from(this.children)
          .filter((el) => el instanceof HTMLDetailsElement)
          .forEach((el, i) => {
            if (items.includes(i)) {
              el.open = true;
            }
          });
      }
    }
  }

  setPersistentState() {
    if (this.id) {
      const items = Array.from(this.children)
        .filter((el) => el instanceof Disclosure)
        .map((el, i) => el.open && i)
        .filter((n) => typeof n === "number");

      storage.session.update(this.tagName, (data) => {
        const key = window.location.pathname;
        if (items.length > 0) {
          return {
            ...data,
            [key]: { ...(data && data[key]), [this.id]: items },
          };
        } else if (data && data[key]) {
          delete data[key][this.id];
          if (Object.values(data[key]).length === 0) {
            delete data[key];
          }
        }
        return data;
      });
    }
  }
}

import {
  animate,
  createElement,
  getAttributes,
  replaceChildren,
  uniqueId,
} from "../utils";

/**
 * A tooltip component.
 *
 * This component should include the attributes and behaviors described in the
 * {@link https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/ | WAI ARIA APG Tooltip pattern}.
 *
 * The component expects a single `<details>` child element that contains a
 * `<summary>` element to be used as the tooltip "label". Additional children
 * of the `<details>` element will be placed in the tooltip popover. This
 * markup provides a serviceable fallback for cases when JavaScript is disabled
 * or custom elements are not supported.
 *
 * Component state can be controlled programmatically by adding or removing
 * the `open` attribute (e.g. `el.toggleAttribute('open')`) or by assigning
 * the element's `open` property (e.g. `el.open = false`).
 *
 * @example
 * ```
 * <sasb-tooltip>
 *   <details>
 *     <summary>My tooltip</summary>
 *     <p>
 *       Lorem ipsum...
 *     </p>
 *   </details>
 * </sasb-tooltip>
 * ```
 */
export default class Tooltip extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-tooltip") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Defines observed attributes
   */
  static observedAttributes = ["open"];

  /**
   * @type {boolean} The current state of the menu popover
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the menu popover
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();

    // Import floating-ui
    if (!window.FloatingUIDOM) {
      window.FloatingUIDOM = import("@floating-ui/dom");
    }

    /**
     * A timeout for managing visibility change delays
     * @type {null|ReturnType<typeof setTimeout>}
     */
    this.visibiltyTimeout = null;
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  async attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const arrow = this.querySelector(".tooltip-arrow");
      const content = this.querySelector(".tooltip-content");
      const label = this.querySelector(".tooltip-label");
      const tooltip = this.querySelector(".tooltip");

      if (!content || !label || !tooltip) {
        return;
      }

      /** @type {typeof import("@floating-ui/dom")} */
      let floatingUI;

      try {
        floatingUI = await window.FloatingUIDOM;
      } catch (error) {
        return;
      }

      if (!floatingUI) {
        return;
      }

      const {
        arrow: arrowMiddleware,
        autoPlacement,
        autoUpdate,
        computePosition,
        inline,
        size,
      } = floatingUI;

      // Compute and set tooltip position with Floating UI
      const setPosition = async () => {
        // Compute position
        const position = await computePosition(label, tooltip, {
          strategy: "fixed",
          middleware: [
            arrowMiddleware({ element: arrow }),
            autoPlacement({
              allowedPlacements: [
                "bottom",
                "bottom-end",
                "bottom-start",
                "top",
                "top-end",
                "top-start",
              ],
              boundary: document.body,
            }),
            inline(),
            size({
              apply({ availableHeight, elements }) {
                Object.assign(elements.floating.style, {
                  maxHeight: `${availableHeight}px`,
                });
              },
            }),
          ],
        });

        // Assign styles
        Object.assign(tooltip.style, {
          left: `${position.x}px`,
          top: `${position.y}px`,
        });

        // Handle arrow placement
        if (arrow && position.middlewareData.arrow) {
          const { x } = position.middlewareData.arrow;
          Object.assign(arrow.style, {
            left: x !== null ? `${x}px` : "",
            top: position.placement.includes("top") ? "" : 0,
            bottom: position.placement.includes("bottom") ? "" : 0,
          });
        }
      };

      const isExpanded = next !== null;

      if (isExpanded) {
        tooltip.hidden = false;
        this._floatingUiCleanup = autoUpdate(label, tooltip, setPosition);
      }

      await animate(tooltip, [
        {
          opacity: isExpanded ? 1 : 0,
          transform: `translateY(${isExpanded ? "0" : "1rem"})`,
        },
      ]);

      if (!this.open) {
        tooltip.hidden = true;

        if (this._floatingUiCleanup) {
          this._floatingUiCleanup();
          delete this._floatingUiCleanup;
        }
      }
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup if necessary
    if (!this.dataset.defined) {
      const details = this.querySelector("details");
      const summary = this.querySelector("summary");

      // Abort if the element does not contain the expected markup
      if (!details || !summary) {
        return;
      }

      // Remove the summary element so we can more easily select the
      // remaining children elements
      summary.remove();

      const id = uniqueId("sasb-tooltip-");

      // Create new component markup
      replaceChildren(
        this,
        // Replace the summary with a `<span>`
        createElement(
          "span",
          {
            ...getAttributes(summary),
            "aria-describedby": id,
            classList: "tooltip-label",
            tabIndex: 0,
          },
          summary.childNodes,
        ),
        // Create the tooltip popover element
        createElement(
          "div",
          {
            hidden: true,
            classList: "tooltip",
            style: {
              left: 0,
              opacity: 0,
              position: "fixed",
              right: "auto",
              top: 0,
              transform: "translateY(1rem)",
            },
          },
          [
            // Create the popover "arrow" element
            createElement("span", {
              classList: "tooltip-arrow",
              style: { position: "absolute" },
            }),
            // Create the inner, content container element
            createElement(
              "div",
              {
                id,
                classList: "tooltip-content",
                role: "tooltip",
              },
              details.childNodes,
            ),
          ],
        ),
      );

      // Mark the component as set up
      this.dataset.defined = "true";
    }

    // Abort if the component couldn't be set up
    if (this.dataset.defined) {
      // Bind event handlers
      this.addEventListener("focusin", this);
      this.addEventListener("focusout", this);
      this.addEventListener("keydown", this);
      this.addEventListener("mouseenter", this);
      this.addEventListener("mouseleave", this);

      // Remove event handlers
      this.cleanup.add(() => {
        this.removeEventListener("focusin", this);
        this.removeEventListener("focusout", this);
        this.removeEventListener("keydown", this);
        this.removeEventListener("mouseenter", this);
        this.removeEventListener("mouseleave", this);
      });
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Handle events
   * @param {Event} event
   */
  handleEvent(event) {
    // Clear the timeout if it exists
    if (this.visibiltyTimeout) {
      clearTimeout(this.visibiltyTimeout);
      this.visibiltyTimeout = null;
    }

    // Show the tooltip on hover and focus
    if (event.type === "focusin" || event.type === "mouseenter") {
      this.visibiltyTimeout = setTimeout(() => {
        this.open = true;
      }, 300);
    }

    // Hide the tooltip on blur and mouseleave
    if (event.type === "focusout" || event.type === "mouseleave") {
      this.visibiltyTimeout = setTimeout(() => {
        this.open = false;
      }, 500);
    }

    // Hide the tooltip when the Escape key is pressed
    if (event.type === "keydown" && event.key === "Escape") {
      this.open = false;
    }
  }
}

import { isPlainObject, prefersReducedMotion } from "./helpers";

/**
 * A wrapper around Element.animate(). The function waits for animations to
 * finish and includes a basic polyfill for legacy browsers.
 * @param {HTMLElement} el The element to animate.
 * @param {Keyframe[]} keyframes The animation keyframes.
 * @param {KeyframeAnimationOptions} [options] The animation options.
 * @returns {Promise<void>}
 */
export async function animate(el, keyframes, options = {}) {
  if (!(el instanceof HTMLElement)) {
    return;
  }

  if (typeof el.animate !== "function") {
    keyframes.forEach((obj) => {
      Object.assign(el.style, obj);
    });
    return;
  }

  try {
    // Get all animating properties and set 'will-change'
    const properties = [...new Set(keyframes.flatMap(Object.keys))];
    el.style.willChange = properties.join(", ");

    // Create the animation
    const animation = el.animate(keyframes, {
      duration: prefersReducedMotion() ? 10 : 150,
      easing: "cubic-bezier(0.5, 0, 0.5, 1)",
      fill: "both",
      ...options,
    });

    // Wait for the animation to finish
    await animation.finished;

    // Commit styles
    animation.commitStyles();

    // Remove 'will-change' declarations
    el.style.removeProperty("will-change");
  } catch (error) {
    // Ignore errors
  }
}

/**
 * Assigns provided attributes to the provided target element.
 *
 * The function is fairly flexible and can handle both HTML/SVG attributes
 * and Element class properties.
 *
 * @param {Element} target The target element.
 * @param {Record<string, unknown>} attributes Attributes to be assigned.
 * @returns {Element} The target element.
 */
export function assignAttributes(target, attributes) {
  if (target instanceof Element && isPlainObject(attributes)) {
    for (const [name, value] of Object.entries(attributes)) {
      // Unset attributes/properties when the provided value is nullish.
      if (value === null || typeof value === "undefined") {
        if (target.hasAttribute(name)) {
          target.removeAttribute(name);
        } else if (name in target) {
          target[name] = null;
        }
        continue;
      }

      // Set attributes with `Element.setAttribute()`.
      if (!(name in target)) {
        target.setAttribute(name, String(value));
        continue;
      }

      // DOMTokenLists such as Element.classList get special treatment.
      // This allows adding tokens without overwriting existing ones.
      if (target[name] instanceof DOMTokenList) {
        const values = Array.isArray(value)
          ? value
          : typeof value === "string"
            ? value.split(/\s+/)
            : [value];
        values.forEach((str) => {
          target[name].add(String(str));
        });
        continue;
      }

      if (isPlainObject(value)) {
        Object.assign(target[name], value);
        continue;
      }

      target[name] = value;
    }
  }

  return target;
}

/**
 * A type-safe wrapper around Element.closest().
 *
 * @param {*} el The source element.
 * @param {*} selectors A CSS selector with which to test the element and
 * its ancestors.
 * @returns {Element|null} The element or its closest ancestor that matches the
 * provided selectors, or null if no such element is found.
 */
export function closest(el, selectors) {
  if (el instanceof Element) {
    return el.closest(selectors);
  }
  if (el instanceof Node) {
    return closest(el.parentElement, selectors);
  }
  return null;
}

/**
 * Creates and returns the specified HTML element, optionally assigning the
 * provided properties/attributes and appending the provided child nodes.
 *
 * The function accepts a variety of formats for both attributes and children.
 * See {@link assignAttributes} and {@link replaceChildren}.
 *
 * @param {string} tagName The name of the element to create.
 * @param {Record<string, unknown>} [properties] Attributes/properties to assign the new element.
 * @param {Parameters<typeof replaceChildren>[1]} [children] Child nodes to append to the new element.
 * @returns {HTMLElement} The newly created element.
 */
export function createElement(tagName, properties, children) {
  const el = document.createElement(tagName);
  if (properties) {
    assignAttributes(el, properties);
  }
  if (children) {
    replaceChildren(el, children);
  }
  return el;
}

/**
 * Attempts to focus the provided element.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 *
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/js/dialog.js
 *
 * @param {*} el The element to focus.
 * @returns {boolean} True if the element could be focused; false if not.
 */
export function focus(el) {
  if (!isFocusableElement(el)) {
    return false;
  }
  try {
    el.focus();
  } catch (error) {
    return false;
  }
  return document.hasFocus() && document.activeElement === el;
}

/**
 * Attempts to focus the first focusable descendant of the provided element.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 *
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/js/dialog.js
 *
 * @param {Node} el The parent element to search.
 * @returns {boolean} True if a focusable element could be found; false if not.
 */
export function focusFirstElement(el) {
  if (el instanceof Node) {
    for (let i = 0; i < el.childNodes.length; i++) {
      let child = el.childNodes[i];
      if (focus(child) || focusFirstElement(child)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Creates and returns a plain object from the provided element's attributes.
 *
 * @param {*} el The source element.
 * @returns {Record<string, string>} A plain object derived from the
 * element's attributes
 */
export function getAttributes(el) {
  /** @type {Record<string, string>} */
  let obj = {};
  if (el instanceof Element && el.hasAttributes()) {
    for (const attr of el.attributes) {
      obj[attr.name] = attr.value;
    }
  }
  return obj;
}

/**
 * Returns an array of Node objects picked from the provided arguments.
 *
 * The function is fairly flexible and can handle nested arrays and native
 * array-like collections such as NodeLists and HTMLCollections. Strings are
 * converted to TextNodes.
 *
 * @param {*} items An collection of values to search.
 * @returns {Node[]} An array of Node objects.
 */
export function getNodes(items) {
  if (Array.isArray(items)) {
    return items.flatMap(getNodes);
  }
  if (items instanceof HTMLCollection || items instanceof NodeList) {
    return Array.from(items);
  }
  if (typeof items === "string") {
    return [document.createTextNode(items)];
  }
  if (items instanceof Node) {
    return [items];
  }
  return [];
}

/**
 * Checks if the provided element can be focused.
 *
 * Adapted from example code provided by the WAI ARIA APG.
 *
 * @link https://www.w3.org/WAI/ARIA/apg/example-index/js/utils.js
 *
 * @param {*} el The element to check.
 * @returns {boolean}
 */
export function isFocusableElement(el) {
  if (
    !(el instanceof HTMLElement) ||
    ("disabled" in el && el.disabled) ||
    !el.offsetParent
  ) {
    return false;
  }
  if (el instanceof HTMLAnchorElement) {
    return Boolean(el.href) && el.rel !== "ignore";
  }
  if (el instanceof HTMLInputElement) {
    return el.type !== "hidden";
  }
  if (
    el instanceof HTMLButtonElement ||
    el instanceof HTMLSelectElement ||
    el instanceof HTMLTextAreaElement
  ) {
    return true;
  }
  if (el.tabIndex >= 0) {
    return true;
  }
  return false;
}

/**
 * Checks if the provided value is a form control element.
 *
 * @param {*} el The element to check.
 * @returns {el is HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement}
 * True if the element is a form control element; otherwise false.
 */
export function isFormControl(el) {
  return (
    el instanceof HTMLInputElement ||
    el instanceof HTMLSelectElement ||
    el instanceof HTMLTextAreaElement
  );
}

/**
 * Replaces an element's children.
 *
 * The function is meant as a stand-in for Element.replaceChildren(), which
 * is still not yet fully supported. The function also accepts children in a
 * variety of formats that Element.replaceChildren() does not, including
 * arrays, HTMLCollections and NodeLists.
 *
 * @see {@link https://caniuse.com/mdn-api_element_replacechildren}.
 * @param {Element|Node} el The parent element.
 * @param {...false|null|string|undefined|Node|NodeList|HTMLCollection|Array<false|null|string|undefined|Node>} children
 * The children to append.
 */
export function replaceChildren(el, ...children) {
  const items = getNodes(children);

  if (el instanceof Element && typeof el.replaceChildren === "function") {
    el.replaceChildren(...items);
  } else if (el instanceof Node) {
    while (el.firstChild) {
      el.removeChild(el.firstChild);
    }
    for (const child of children) {
      const node =
        typeof child === "string" ? document.createTextNode(child) : child;
      if (node instanceof Node) {
        el.appendChild(node);
      }
    }
  }
}

/**
 * Submits the provided form.
 *
 * This is meant to be a replacement for HTMLFormElement.requestSubmit()
 * that's safe for legacy browsers.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
 *
 * @param {HTMLFormElement} form        The form to submit.
 * @param {HTMLElement}     [submitter] A submit button to use.
 */
export function requestSubmit(form, submitter) {
  if (typeof form.requestSubmit === "function") {
    form.requestSubmit(submitter);
  } else if (
    submitter instanceof Element &&
    submitter.matches("button, [type='submit']")
  ) {
    submitter.click();
  } else {
    form.dispatchEvent(new Event("submit", { cancelable: true }));
  }
}

import {
  assignAttributes,
  closest,
  createElement,
  escapeRegExp,
  focus,
  getAttributes,
  replaceChildren,
  uniqueId,
} from "../utils";

/**
 * A menu button component.
 *
 * This component should include the attributes and behaviors described in the
 * {@link https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/examples/menu-button-links/ | WAI ARIA APG Navigation Menu Button Example}.
 *
 * The component expects to contain exactly one `<details>` child element that
 * contains a `<summary>` element (to serve as the menu button) and a `<ul>` or
 * `<ol>` element (to serve as the menu). This specific markup provides a
 * serviceable fallback for cases when JavaScript is disabled or custom
 * elements are not supported.
 *
 * @example
 * ```
 * <sasb-menu-item>
 *   <details>
 *     <summary>My menu button</summary>
 *     <ul>
 *       <li>
 *         <a href="/page-1">Page 1</a>
 *       </li>
 *       <li>
 *         <a href="/page-2">Page 1</a>
 *       </li>
 *     </ul>
 *   </details>
 * </sasb-menu-item>
 * ```
 */
export default class MenuButton extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-menu-button") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Defines observed attributes
   */
  static observedAttributes = ["open"];

  /**
   * @type {boolean} The current state of the menu popover
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the menu popover
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();

    this.inputText = "";
    this.inputTimestamp = 0;
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const button = this.querySelector("button");
      const menu = this.querySelector("[role='menu']");

      if (button && menu) {
        const isExpanded = next !== null;
        button.setAttribute("aria-expanded", isExpanded.toString());
        assignAttributes(menu, { hidden: !isExpanded });
      }
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup if necessary
    if (!this.dataset.defined) {
      const details = this.querySelector("details");
      const summary = details && details.querySelector("summary");
      const list = details && details.querySelector("ul,ol");

      // Abort if the component doesn't contain the expected markup.
      if (!summary || !list) {
        return;
      }

      const id = uniqueId("sasb-menu-");

      // Assign necessary attributes to the menu element
      assignAttributes(list, {
        "aria-labelledby": `${id}-button`,
        hidden: true,
        id: uniqueId("sasb-menu-"),
        role: "menu",
      });

      // Assign necessary attributes to the menu item elements
      list.querySelectorAll("li").forEach((listitem) => {
        assignAttributes(listitem, { role: "none" });
        listitem.querySelectorAll("a,button").forEach((el) => {
          assignAttributes(el, { role: "menuitem", tabindex: -1 });
        });
      });

      // Replace the component markup
      replaceChildren(
        this,
        // Create the button element
        createElement(
          "button",
          {
            ...getAttributes(summary),
            "aria-controls": list.id,
            "aria-expanded": "false",
            "aria-haspopup": "true",
            id: `${id}-button`,
            type: "button",
          },
          summary.childNodes,
        ),
        // Append the menu element
        list,
      );

      // Mark the element as set up.
      this.dataset.defined = "true";
    }

    // Abort if the component couldn't be set up
    if (this.dataset.defined) {
      // Bind event handlers
      this.addEventListener("click", this);
      this.addEventListener("focusout", this);
      this.addEventListener("keydown", this);
      this.addEventListener("mouseenter", this);

      // Remvoe event handlers when the component is disconnected
      this.cleanup.add(() => {
        this.removeEventListener("click", this);
        this.removeEventListener("focusout", this);
        this.removeEventListener("keydown", this);
        this.removeEventListener("mouseenter", this);
      });
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Handle events
   * @param {Event} event
   */
  handleEvent(event) {
    // Toggle the component state on click events
    if (event.type === "click") {
      // Ensure the button receives focus on click (this isn't the default
      // behavior for Safari).
      if (!this.contains(document.activeElement)) {
        focus(this.querySelector("button[aria-expanded]"));
      }

      // Get the target menu item
      const item = closest(event.target, "[role='menuitem']");

      // If the target is a menu item, then update the "selected" "slot"
      if (item && this.contains(item)) {
        this.querySelectorAll("[data-slot='selected']").forEach((el) => {
          el.textContent = item.textContent;
        });
      }

      // Toggle the component state
      this.open = !this.open;
    }

    // Collapse the menu when the component loses focus
    if (event.type === "focusout" && !this.contains(event.relatedTarget)) {
      this.open = false;
    }

    // Handle keyboard events
    if (event.type === "keydown") {
      // Collapse the menu when the "Escape" key is pressed
      if (event.key === "Escape" && this.open) {
        event.preventDefault();
        this.open = false;
        focus(this.querySelector("button[aria-expanded]"));
      }

      // Get all menu items
      const items = Array.from(this.querySelectorAll("[role='menuitem']"));

      // Expand the menu and move focus to the first item on "Enter",
      // "ArrowDown" and spacebar key presses
      if (
        !this.open &&
        (event.key === "ArrowDown" ||
          event.key === "Enter" ||
          event.key === " ")
      ) {
        event.preventDefault();
        this.open = true;
        focus(items[0]);
      }

      // Expand the menu and move focus to the last item on "ArrowUp"
      // key presses
      if (!this.open && event.key === "ArrowUp") {
        event.preventDefault();
        this.open = true;
        focus(items[items.length - 1]);
      }

      // Move focus between menu items on "ArrowDown", "ArrowUp", "Home", and
      // "End" key presses
      if (
        (this.open && event.key === "ArrowDown") ||
        event.key === "ArrowUp" ||
        event.key === "Home" ||
        event.key === "End"
      ) {
        event.preventDefault();
        let index = items.findIndex((el) => el === document.activeElement);
        if (event.key === "ArrowDown") {
          index = index + 1 < items.length ? index + 1 : 0;
        }
        if (event.key === "ArrowUp") {
          index = index - 1 >= 0 ? index - 1 : items.length - 1;
        }
        if (event.key === "Home") {
          index = 0;
        }
        if (event.key === "End") {
          index = items.length - 1;
        }
        focus(items[index]);
      }

      // Move focus to macthing menu items when word character keys are pressed
      if (this.open && /^\w$/.test(event.key)) {
        event.preventDefault();
        const now = performance.now();
        if (now - this.inputTimestamp > 750) {
          this.inputText = "";
        }
        this.inputTimestamp = now;
        this.inputText = `${this.inputText || ""}${event.key}`;
        const re = new RegExp(`^\\s*${escapeRegExp(this.inputText)}`, "i");
        const index = items.findIndex((el) => re.test(el.textContent || ""));
        focus(items[index]);
      }
    }
  }
}

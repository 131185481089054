import { defineElements } from "./components";
import { addReportsSearchFormHandlers } from "./modules/reports";

defineElements();

(function onReady(fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
})(main);

function main() {
  // Bind reports search form handlers
  document
    .querySelectorAll("form[data-rel~='reports-search']")
    .forEach((form) => {
      addReportsSearchFormHandlers(form);
    });
}

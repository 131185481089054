import closeIcon from "../../icons/close.svg?raw";
import {
  animate,
  closest,
  createElement,
  focusFirstElement,
  getAttributes,
  lockDocumentScroll,
  replaceChildren,
  uniqueId,
  unlockDocumentScroll,
} from "../utils";

/**
 * A component for slide-out "hamburger" menu drawers.
 *
 * The component expects a single `<details>` child element that
 * contains a `<summary>` element (to be used as the "hamburger" button).
 * Additional children of the `<details>` element will be moved into the
 * drawer. This markup provides a serviceable fallback for cases when
 * JavaScript is disabled or custom elements are not supported.
 *
 * The component's visibility is toggled by clicks on any element with an
 * `"aria-controls"` attribute that references the dialog's id.
 *
 * Component state can also be controlled programmatically by adding or
 * removing the `open` attribute (e.g. `el.toggleAttribute('open')`) or by
 * assigning the element's `open` property (e.g. `el.open = false`).
 *
 * @example
 * ```
 * <sasb-navigation-drawer>
 *   <nav aria-label="primary menu">
 *     <details>
 *       <summary>Open menu</summary>
 *       <ul>
 *         <li>Menu item 1</li>
 *         <li>Menu item 2</li>
 *         <li>Menu item 3</li>
 *       </ul>
 *     </details>
 *   </nav>
 * </sasb-navigation-drawer>
 * ```
 */
export default class NavigationDrawer extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-navigation-drawer") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Defines observed attributes
   */
  static observedAttributes = ["open"];

  /**
   * @type {boolean} The current state of the menu popover
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the menu popover
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const drawer = this.querySelector(".drawer");
      const content = this.querySelector(".drawer-content");

      if (!content || !drawer) {
        return;
      }

      const isExpanded = next !== null;

      if (isExpanded) {
        drawer.hidden = false;
        lockDocumentScroll(drawer);
      } else {
        unlockDocumentScroll();
      }

      document
        .querySelectorAll(`[aria-expanded][aria-controls='${drawer.id}']`)
        .forEach((el) => {
          el.setAttribute("aria-expanded", isExpanded.toString());
        });

      Promise.all([
        animate(drawer, [{ opacity: isExpanded ? 1 : 0 }]),
        animate(content, [
          { transform: `translateX(${isExpanded ? "0" : "-100%"})` },
        ]),
      ]).then(() => {
        if (!this.open) {
          drawer.hidden = true;
        } else {
          focusFirstElement(drawer);
        }
      });
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup if necessary
    if (!this.dataset.defined) {
      const details = this.querySelector("details");
      const summary = details && details.querySelector("summary");

      // Abort if the expected markup isn't present
      if (!details || !summary) {
        return;
      }

      const id = uniqueId("sasb-navigation-drawer-");

      // Remove the summary element so we can more easily select the
      // remaining children elements
      summary.remove();

      // Create new component markup
      replaceChildren(
        details.parentElement,
        // Ceate the "hamburger" trigger button
        createElement(
          "button",
          {
            ...getAttributes(summary),
            "aria-controls": id,
            "aria-expanded": false,
            classList: "drawer-button",
          },
          summary.children,
        ),
        // Create the drawer container element
        createElement(
          "div",
          { classList: "drawer", hidden: true, id, style: { opacity: 0 } },
          // Create the inner, drawer content container element
          createElement(
            "div",
            {
              classList: "drawer-content",
              style: { transform: "translateX(-100%)" },
            },
            [
              // Create a close button inside the drawer
              createElement(
                "button",
                {
                  "aria-controls": id,
                  "aria-expanded": false,
                  "aria-label": "Close menu",
                  classList: "drawer-close",
                  type: "button",
                },
                createElement("span", {
                  "aria-hidden": "true",
                  classList: "sasb-icon",
                  innerHTML: closeIcon,
                }),
              ),
              // Append the existing content
              ...details.children,
            ],
          ),
        ),
      );

      // Mark the component as setup
      this.dataset.defined = "true";
    }

    // Abort if the component couldn't be set up
    if (this.dataset.defined) {
      // Bind event handlers
      document.addEventListener("click", this);
      document.addEventListener("keydown", this);

      // Remvoe event handlers when the component is disconnected
      this.cleanup.add(() => {
        document.removeEventListener("click", this);
        document.removeEventListener("keydown", this);
      });
    }

    // Ensure the component state is update appropriately if the component
    // is hidden at larger viewports
    if ("ResizeObserver" in window) {
      const observer = new ResizeObserver(() => {
        const drawer = this.querySelector(".drawer");
        // If the drawer is open but has no offsetParent, then it's been hidden
        // with CSS and we should ensure the state is correct
        if (this.open && drawer && !drawer.offsetParent) {
          this.open = false;
        }
      });

      observer.observe(this);
      this.cleanup.add(() => observer.disconnect());
    }
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Handle events
   * @param {Event} event
   * @returns {void}
   */
  handleEvent(event) {
    const drawer = this.querySelector(".drawer");

    if (!drawer) {
      return;
    }

    // Handle click events
    if (event.type === "click" && event.target) {
      // Close the drawer when the "overlay" is clicked
      if (event.target === drawer) {
        this.open = false;
        return;
      }
      // Toggle the drawer state when trigger buttons are clicked
      if (closest(event.target, `[aria-controls='${drawer.id}']`)) {
        this.open = !this.open;
        return;
      }
    }

    // CLose the drawer when the Escape key is clicked
    if (event.type === "keydown" && event.key === "Escape" && this.open) {
      event.preventDefault();
      this.open = false;
    }
  }
}

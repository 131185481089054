import pauseIcon from "../../icons/pause.svg?raw";
import playIcon from "../../icons/play.svg?raw";
import { createElement } from "../utils";

let swiper;
let swiperModules;

/**
 * A Swiper-based carousel component.
 *
 * The component expects specific child markup, particularly `.swiper-*`
 * classes. See the {@link https://swiperjs.com/swiper-api | Swiper docs} for
 * details.
 *
 * @example
 * ```html
 * <sasb-carousel autoplay>
 *   <div class="swiper-wrapper">
 *     <div class="swiper-slide">Slide 1...</div>
 *     <div class="swiper-slide">Slide 2...</div>
 *     <div class="swiper-slide">Slide 3...</div>
 *   </div>
 *   <button class="swiper-button-prev" type="button">Previous</button>
 *   <button class="swiper-button-next" type="button">Next</button>
 * </sasb-carousel>
 * ```
 */
export default class Carousel extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-carousel") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Creates a new element instance
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();

    // Import the swiper library
    if (!swiper) {
      swiper = import("swiper");
    }

    // Import additional swiper features
    if (!swiperModules) {
      swiperModules = import("swiper/modules");
    }

    /** @type {import("swiper").Swiper|null} */
    this.swiper = null;
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  async connectedCallback() {
    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Await the dynamic module loading
    try {
      [swiper, swiperModules] = await Promise.all([swiper, swiperModules]);
    } catch (error) {
      // Ignore
    }

    // Abort if the Swiper lib couldn't be loaded.
    if (!swiper || !swiperModules) {
      return;
    }

    const Swiper = swiper.default;
    const { A11y, Autoplay, Navigation, Pagination } = swiperModules;

    // Add the swiper class if necessary.
    this.classList.add("swiper");

    const options = {
      modules: [A11y],
      slidesPerView: "auto",
      spaceBetween: parseInt(this.getAttribute("space-between") || "0"),
    };

    // Enable autoplay if the `autoplay` boolean attribute is present.
    if (this.hasAttribute("autoplay")) {
      // Get play/pause button
      let button = this.querySelector(".swiper-button-play");

      // Create play/pause button if none exists
      if (!button) {
        button = createElement(
          "button",
          {
            "aria-label": "Stop automatic slide show",
            className: "swiper-button-play",
            type: "button",
          },
          [
            createElement("span", {
              "aria-hidden": "true",
              classList: "sasb-icon play-icon",
              hidden: true,
              innerHTML: playIcon,
            }),
            createElement("span", {
              "aria-hidden": "true",
              classList: "sasb-icon pause-icon",
              hidden: true,
              innerHTML: pauseIcon,
            }),
          ],
        );
        this.prepend(button);
      }

      /**
       * Handle play/pause button clicks
       */
      const handleClick = () => {
        if (this.swiper) {
          if (this.swiper.autoplay.running && !this.swiper.autoplay.paused) {
            this.swiper.autoplay.stop();
          } else {
            this.swiper.autoplay.start();
          }
        }
      };

      // Add autoplay-related event handlers
      button.addEventListener("click", handleClick);

      // Cleanup autoplay-related event handlers
      this.cleanup.add(() => {
        if (button) {
          button.removeEventListener("click", handleClick);
        }
      });

      /**
       * React to autoplay state changed
       */
      const handleAutoplay = () => {
        if (!this.swiper) {
          return;
        }
        const isPlaying = this.swiper.autoplay.running;
        // Update play/pause buttons
        this.querySelectorAll(".swiper-button-play").forEach((el) => {
          // Update button text
          const control = isPlaying ? "Stop" : "Start";
          el.setAttribute("aria-label", `${control} automatic slide show`);
          // Toggle icon visibility
          el.querySelectorAll(".sasb-icon").forEach((icon) => {
            if (icon.matches(".play-icon")) {
              icon.hidden = isPlaying;
            }
            if (icon.matches(".pause-icon")) {
              icon.hidden = !isPlaying;
            }
          });
        });
      };

      options.autoplay = { enabled: false, delay: 5000 };
      options.modules.push(Autoplay);
      options.breakpoints = { 448: { autoplay: { enabled: true } } };
      options.on = {
        ...(options.on || {}),
        autoplayStart: handleAutoplay,
        autoplayStop: handleAutoplay,
        init: handleAutoplay,
        breakpoint: (swiper, params) => {
          if (params.autoplay) {
            this.querySelectorAll(".swiper-button-play").forEach((el) => {
              if (el instanceof HTMLElement) {
                el.hidden = !params.autoplay.enabled;
              }
            });
          }
          if (params.autoplay.enabled && !swiper.autoplay.running) {
            swiper.autoplay.start();
          } else if (swiper.autoplay.running && !params.autoplay.enabled) {
            swiper.autoplay.stop();
          }
        },
      };
    }

    const nextEl = this.querySelector(".swiper-button-next");
    const prevEl = this.querySelector(".swiper-button-prev");

    // Enable navigation if the next/prev markup is present.
    if (nextEl || prevEl) {
      options.navigation = { nextEl, prevEl };
      options.modules.push(Navigation);
    }

    const pagninationEl = this.querySelector(".swiper-pagination");

    // Enable navigation if the pagination markup is present.
    if (pagninationEl) {
      options.pagination = {
        el: pagninationEl,
        clickable: true,
        type: "bullets",
      };
      options.modules.push(Pagination);
    }

    new Swiper(this, options);

    // Destroy the swiper instance when the component is disconnected.
    this.cleanup.add(() => {
      if (this.swiper) {
        this.swiper.destroy(true, true);
      }
    });
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }
}

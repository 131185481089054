import {
  closest,
  createElement,
  getCookie,
  isFormControl,
  isPlainObject,
} from "../utils";

import Form from "./Form";

export default class StandardsForm extends Form {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-standards-form") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Call parent method.
    super.connectedCallback();

    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Abort if no child `<form>` element exists.
    if (!this.form) {
      return;
    }

    // Get the "proxy" standards input element. The element exists to provide
    // native, client-side validation for the group of standards checkboxes,
    // which can't take advantage of, e.g., the `required` attribute.
    let proxyInput = this.form.querySelector(
      "input[name='sasb-standards-proxy']",
    );

    // If no proxy element exists, create one and append it to the form.
    if (!proxyInput) {
      proxyInput = createElement("input", {
        id: `${this.form.id}-sasb-standards-proxy`,
        name: "sasb-standards-proxy",
        required: true,
        style: { display: "block", height: 0, width: 0, opacity: 0 },
        type: "text",
        value: "",
      });
      this.form.append(proxyInput);
    }

    // Update form state.
    const setState = () => {
      if (this.form) {
        // Get all standards/downloads values.
        const standards = new FormData(this.form).getAll("sasb-standards[]");
        // Require that the user has specified at least one standard.
        const isValid = standards.length > 0;

        // Update elements with the total number of selected standards.
        document
          .querySelectorAll(`[data-rel='${this.form.id}'][data-value='total']`)
          .forEach((el) => {
            el.textContent = standards.length.toString();
          });

        // Update the value and validity of the proxyInput.
        if (proxyInput instanceof HTMLInputElement) {
          proxyInput.value = isValid ? "true" : "";
          proxyInput.setCustomValidity(
            isValid ? "" : "Please select at least one standard for download.",
          );
        }
      }
    };

    setState();

    /**
     * @param {Event} event
     */
    const handleChange = (event) => {
      if (
        this.form &&
        (event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLSelectElement ||
          event.target instanceof HTMLTextAreaElement) &&
        event.target.form === this.form
      ) {
        setState();
      }
    };

    document.addEventListener("change", handleChange);
    this.cleanup.add(() =>
      document.removeEventListener("change", handleChange),
    );

    /**
     * @param {Event} event
     */
    const handleClick = (event) => {
      const button =
        this.form && closest(event.target, `button[form='${this.form.id}']`);
      if (
        this.form &&
        button instanceof HTMLButtonElement &&
        button.name &&
        button.value
      ) {
        let input = /** @type {HTMLInputElement|null} */ (
          this.form.querySelector(`input[type='hidden'][name='${button.name}']`)
        );
        if (!input) {
          input = /** @type {HTMLInputElement} */ (
            createElement("input", { name: button.name, type: "hidden" })
          );
          this.form.append(input);
        }
        input.value = button.value;
      }
    };

    document.addEventListener("click", handleClick);
    this.cleanup.add(() => document.removeEventListener("click", handleClick));

    /**
     *
     */
    const handleSubmit = async () => {
      const hubspotutk = getCookie("hubspotutk");
      if (hubspotutk) {
        try {
          const endpoint = window.SASB.restEndpoints.hsFormFieldDefaults;
          const data = new FormData(this.form);
          data.append("hubspotutk", hubspotutk);
          fetch(endpoint, { method: "POST", body: data });
        } catch (error) {
          // Ignore
        }
      }
    };

    document.addEventListener("submit", handleSubmit);
    this.cleanup.add(() =>
      document.removeEventListener("submit", handleSubmit),
    );
  }

  /**
   *
   */
  setup() {
    if (typeof super.setup === "function") {
      super.setup();
    }

    const setDefaultFieldValues = async (token) => {
      try {
        const url = new URL(window.SASB.restEndpoints.hsFormFieldDefaults);
        url.searchParams.append("hubspotutk", token);
        const response = await fetch(url);
        const data = await response.json();
        if (this.form && isPlainObject(data)) {
          Object.entries(data).forEach(([name, value]) => {
            const el = this.form.elements.namedItem(name);
            if (
              !/(recaptcha|hcaptcha|nonce)/.test(name) &&
              isFormControl(el) &&
              !el.value
            ) {
              el.value = value;
            }
          });
        }
      } catch (error) {
        // Ignore
      }
    };

    const hubspotutk = getCookie("hubspotutk");

    if (hubspotutk) {
      setDefaultFieldValues(hubspotutk);
    }
  }
}

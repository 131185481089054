import { requestSubmit } from "../utils";

export function addReportsSearchFormHandlers(form) {
  if (form instanceof HTMLFormElement) {
    form.addEventListener("change", () => {
      requestSubmit(form);
    });

    jQuery(form).on("change", () => {
      requestSubmit(form);
    });

    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      updateReportsSearchResults(form);
    });

    document.addEventListener("click", (event) => {
      const button =
        event.target instanceof Element &&
        event.target.closest(`button[data-rel=${form.dataset.rel}]`);
      if (button) {
        switch (button.dataset.action) {
          case "remove": {
            if (button.value in form.elements) {
              const el = form.elements[button.value];
              el.value = el.options ? el.options[0].value : "";
              updateReportsSearchResults(form);
            }
            break;
          }
          case "reset": {
            form.reset();
            updateReportsSearchResults(form);
            break;
          }
        }
        if (typeof jQuery.prototype.niceSelect === "function") {
          jQuery(".dropdown", form).each((i, el) =>
            jQuery(el).niceSelect("update"),
          );
        }
      }
    });
  }
}

async function updateReportsSearchResults(form) {
  const data = Object.fromEntries(new FormData(form).entries());
  const resultsContainer = document.getElementById(form.dataset.controls);
  if (
    resultsContainer &&
    resultsContainer.getAttribute("aria-busy") !== "true"
  ) {
    resultsContainer.setAttribute("aria-busy", "true");
    const { html } = await getReportsSearchResults(data);
    if (html) {
      resultsContainer.innerHTML = html;
    }
    resultsContainer.removeAttribute("aria-busy");
  }
}

async function getReportsSearchResults(filters) {
  const { restEndpoints } = window.SASB || {};
  const endpoint = restEndpoints && restEndpoints.reportsSearch;
  if (endpoint) {
    try {
      const url = new URL(endpoint);
      url.search = new URLSearchParams(filters);
      url.searchParams.set("format", "html");
      const response = await fetch(url);
      const data = await response.json();
      if (data && data.html) {
        return data;
      }
    } catch (error) {
      // TODO Handle error reporting
    }
  }
  console.error("Couldn't complete reports search.");
  return {};
}

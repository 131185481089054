import { createElement, uniqueId } from "../utils";

import Form from "./Form";

const INVALID_INPUT_ERROR = "Invalid search input";

export default class CompanySearchForm extends Form {
  /**
   * Define the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-company-search-form") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   *
   * @param {string} query The search string.
   * @param {object} [options] Search options.
   * @param {string} [options.variant] The HTML response template variant.
   * @returns {Promise<{ html: string; total: number }>}
   */
  static async fetchResults(query, options = {}) {
    if (!query || query.length < 1) {
      throw new Error(INVALID_INPUT_ERROR);
    }

    const url = new URL(window.SASB.restEndpoints.companySearch);
    const params = new URLSearchParams({
      s: query,
      format: "html",
      ...options,
    });
    url.search = params.toString();

    const locale = new URLSearchParams(window.location.search).get("lang");
    if (locale) {
      url.searchParams.set("locale", locale);
    }

    const response = await fetch(url);
    const result = await response.json();

    return result;
  }

  /**
   * Set up the element and bind event listeners.
   */
  connectedCallback() {
    // Call parent method.
    super.connectedCallback();

    // Exit early if the component is not connected
    if (!this.isConnected) {
      return;
    }

    // Abort if no child `<form>` element exists.
    if (!this.form) {
      return;
    }

    this.setup();

    /** @param {SubmitEvent} event */
    const handleSubmit = async (event) => {
      event.preventDefault();

      const output = document.getElementById(this.form.dataset.controls || "");
      const description =
        output &&
        document.getElementById(output.getAttribute("aria-describedby"));
      const resultsContainer = output && output.querySelector("[data-results]");

      try {
        output.setAttribute("aria-busy", "true");
        description.textContent = "Loading\u2026";
        const search = String(new FormData(this.form).get("search"));
        const variant = this.getAttribute("results-variant");
        const result = await CompanySearchForm.fetchResults(search, {
          variant,
        });
        description.textContent = `Found ${result.total} results`;
        resultsContainer.innerHTML = result.html;
      } catch (error) {
        resultsContainer.innerHTML = "";
        description.textContent =
          error.message === INVALID_INPUT_ERROR
            ? "Please provide a search string"
            : "Something went wrong. Search results couldn't be fetched.";
      } finally {
        resultsContainer.setAttribute("aria-busy", "false");
      }
    };

    this.form.addEventListener("submit", handleSubmit);
    this.cleanup.add(
      () => this.form && this.form.removeEventListener("submit", handleSubmit),
    );
  }

  /**
   * Set up the component markup.
   *
   * Note that this method may be called repeatedly and should be idempotent.
   */
  setup() {
    if (typeof super.setup === "function") {
      super.setup();
    }

    let output = document.getElementById(this.form.dataset.controls || "");

    if (!output) {
      output = createElement("div", { id: uniqueId(this.tagName) });
      this.form.dataset.controls = output.id;
      this.form.after(output);
    }

    let description = document.getElementById(
      output.getAttribute("aria-describedby") || "",
    );

    if (!description) {
      description = createElement("h2", {
        class: "mb-6 text-xs",
        id: uniqueId(this.tagName),
      });
      output.prepend(description);
      output.setAttribute("aria-describedby", description.id);
    }

    let results = output.querySelector("[data-results]");

    if (!results) {
      results = createElement("div", { dataset: { results: "" } });
      output.append(results);
    }
  }
}

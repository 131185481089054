import Accordion from "./Accordion";
import Carousel from "./Carousel";
import CompanySearchForm from "./CompanySearchForm";
import Disclosure from "./Disclosure";
import Form from "./Form";
import MenuButton from "./MenuButton";
import ModalDialog from "./ModalDialog";
import NavigationDrawer from "./NavigationDrawer";
import NavigationMenu from "./NavigationMenu";
import StandardsForm from "./StandardsForm";
import Tooltip from "./Tooltip";
import TruncatedText from "./TruncatedText";

export function defineElements() {
  if ("customElements" in window) {
    Accordion.define();
    Carousel.define();
    CompanySearchForm.define();
    Disclosure.define();
    Form.define();
    MenuButton.define();
    ModalDialog.define();
    NavigationDrawer.define();
    NavigationMenu.define();
    StandardsForm.define();
    Tooltip.define();
    TruncatedText.define();
  }
}

import closeIcon from "../../icons/close.svg?raw";
import {
  animate,
  assignAttributes,
  closest,
  createElement,
  focusFirstElement,
  lockDocumentScroll,
  unlockDocumentScroll,
} from "../utils";

/**
 * A modal dialog component.
 *
 * The component's visibility is toggled by clicks on any element with an
 * `"aria-controls"` or `"data-controls"` attribute that references the
 * dialog's id.
 *
 * Component state can also be controlled programmatically by adding or
 * removing the `open` attribute (e.g. `el.toggleAttribute('open')`) or by
 * assigning the element's `open` property (e.g. `el.open = false`).
 *
 * @example
 * ```
 * <button data-controls="dialog-123">Open Dialog</button>
 * <sasb-modal-dialog
 *   id="dialog-123"
 *   aria-labelledby="dialog-123-heading"
 * >
 *   <h3 id="dialog-123-heading">My dialog</h3>
 *   <div>My dialog content</div>
 * </sasb-modal-dialog>
 * ```
 */
export default class ModalDialog extends HTMLElement {
  /**
   * Defines the element in the document's custom element registry
   * @param {string} [tag] The tag to use in the element definition
   */
  static define(tag = "sasb-modal-dialog") {
    if (!customElements.get(tag)) {
      customElements.define(tag, this);
    }
  }

  /**
   * Defines observed attributes
   */
  static observedAttributes = ["open"];

  /**
   * @type {boolean} The current state of the menu popover
   */
  get open() {
    return this.hasAttribute("open");
  }

  /**
   * @param {boolean} value The new state of the menu popover
   */
  set open(value) {
    this.toggleAttribute("open", !!value);
  }

  /**
   * Creates an element instance.
   */
  constructor() {
    super();

    /**
     * A collection for cleanup functions, e.g. removing event listeners.
     * @type {Set}
     */
    this.cleanup = new Set();
  }

  /**
   * Reacts to changes of observed attributes. The function makes DOM updates
   * to reflect the current state of the component.
   * @param {string} name The changed attribute name
   * @param {*} prev The previous value of the attribute
   * @param {*} next The new value of the attribute
   */
  attributeChangedCallback(name, prev, next) {
    if (name === "open") {
      const dialog = this.matches(".modal-dialog") && this;
      const wrapper = closest(this, ".modal-wrapper");

      if (!wrapper || !dialog) {
        return;
      }

      const isExpanded = next !== null;

      if (isExpanded) {
        wrapper.hidden = false;
        wrapper.scrollTop = 0;
        lockDocumentScroll(dialog);
      } else {
        unlockDocumentScroll();
      }

      this.querySelectorAll(
        `[aria-expanded][aria-controls='${dialog.id}']`,
      ).forEach((el) => {
        el.setAttribute("aria-expanded", isExpanded.toString());
      });

      Promise.all([
        animate(wrapper, [{ opacity: isExpanded ? 1 : 0 }]),
        animate(dialog, [
          { transform: `translateY(${isExpanded ? "0" : "4rem"})` },
        ]),
      ]).then(() => {
        if (!this.open) {
          wrapper.hidden = true;
        } else {
          focusFirstElement(dialog);
        }
      });
    }
  }

  /**
   * Sets up the element once it has been added to the DOM.
   */
  connectedCallback() {
    // Exit early if the component is not connected.
    if (!this.isConnected) {
      return;
    }

    // Set up the component markup if necessary
    if (!this.dataset.defined) {
      // Assign necessary attributes to the dialog element
      assignAttributes(this, {
        "aria-modal": "true",
        classList: "modal-dialog",
        dataset: { defined: "true" },
        role: "dialog",
        style: { transform: "translateY(4rem)" },
      });

      // Prepend a "close" button
      this.prepend(
        createElement(
          "button",
          {
            "aria-label": "Close dialog",
            classList: "modal-close",
            dataset: { controls: this.id },
            type: "button",
          },
          createElement("span", {
            "aria-hidden": "true",
            classList: "sasb-icon",
            innerHTML: closeIcon,
          }),
        ),
      );

      // Move the element to the document root
      document.body.append(
        // Create a "wrapper" parent element
        createElement(
          "div",
          {
            classList: "modal-wrapper",
            hidden: true,
            style: { opacity: 0 },
          },
          this,
        ),
      );
    }

    // Bind event handlers. Use delegation to ensure all possible controls
    // are included.
    document.addEventListener("click", this);
    document.addEventListener("keydown", this);

    // Remove event handlers when the element is disconnected
    this.cleanup.add(() => {
      document.removeEventListener("click", this);
      document.removeEventListener("keydown", this);
    });
  }

  /**
   * Clean up side effects when the component is disconnected.
   */
  disconnectedCallback() {
    // Call and delete all cleanup functions
    if (this.cleanup) {
      this.cleanup.forEach((fn) => fn());
      this.cleanup.clear();
    }
  }

  /**
   * Handle events
   * @param {Event} event
   */
  handleEvent(event) {
    // Toggle the dialog state on appropriate click events
    if (
      event.type === "click" &&
      (closest(
        event.target,
        `[aria-controls='${this.id}'],[data-controls='${this.id}']`,
      ) ||
        event.target === this.parentElement)
    ) {
      this.open = !this.open;
    }

    // Hide the dialog when the escape key is pressed
    if (event.type === "keydown" && event.key === "Escape" && this.open) {
      event.preventDefault();
      this.open = false;
    }
  }
}
